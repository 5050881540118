import "rxjs/add/operator/finally";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { CommonProcess } from "@/shared/common-process";

const cp = new CommonProcess();

export class AgreementTemplateServiceProxy {
  private instance: AxiosInstance;
  private baseUrl: string;
  protected jsonParseReviver:
    | ((key: string, value: any) => any)
    | undefined = undefined;

  constructor(baseUrl?: string, instance?: AxiosInstance) {
    this.instance = instance ? instance : axios.create();
    this.baseUrl = baseUrl ? baseUrl : "";
  }

  /**
   * 合同模板管理---详情
   * @param id
   */
  agreementTemplateGetEditById(id: string): Promise<any> {
    let url_ =
      this.baseUrl + `/api/services/app/AgreementTemplate/GetEditById?`;
    if (id === null) {
      throw new Error("The parameter 'id' cannot be null.");
    } else if (id !== undefined) {
      url_ += "id=" + encodeURIComponent("" + id) + "&";
    }
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  /**
   * 合同模板管理---分页
   * @param name
   * @param filterText
   * @param sorting
   * @param maxResultCount
   * @param skipCount
   */
  agreementTemplateGetPaged(
    name: string,
    filterText: string,
    sorting: string,
    maxResultCount: number,
    skipCount: number
  ): Promise<any> {
    let url_ =
      this.baseUrl +
      `/api/services/app/AgreementTemplate/GetPaged?name=${name}&filterText=${filterText}&sorting=${sorting}&maxResultCount=${maxResultCount}&skipCount=${skipCount}`;
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  /**
   * 保存
   * @param body
   */
  agreementTemplateSave(body: any | undefined): Promise<any> {
    let url_ = this.baseUrl + `/api/services/app/AgreementTemplate/Save`;
    url_ = url_.replace(/[?&]$/, "");

    const content_ = body;

    let options_ = <AxiosRequestConfig>{
      data: content_,
      method: "POST",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  /**
   * 保存
   * @param body
   */
  analysisPDF(fileInfo: any | undefined): Promise<any> {
    let url_ = this.baseUrl + `/api/services/app/AgreementTemplate/AnalysisPDF`;

    let options_ = <AxiosRequestConfig>{
      data: fileInfo,
      method: "POST",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  /**
   * 保存
   * @param body
   */
  preViewPdf(body: any | undefined): Promise<any> {
    let url_ = this.baseUrl + `/api/services/app/AgreementTemplate/PreViewPdf`;
    url_ = url_.replace(/[?&]$/, "");

    const content_ = body;

    let options_ = <AxiosRequestConfig>{
      data: content_,
      method: "POST",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  /**
   * 协议模版---删除
   * @param id
   */
  agreementTemplateDelete(id: number | undefined): Promise<void> {
    let url_ = this.baseUrl + "/api/services/app/AgreementTemplate/Delete?";
    if (id === null) {
      throw new Error("The parameter 'id' cannot be null.");
    } else if (id !== undefined) {
      url_ += "id=" + encodeURIComponent("" + id) + "&";
    }
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "DELETE",
      url: url_,
      headers: {},
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  /**
   * 同步模板到电子签模块
   */
  agreementTemplateAsyncElectronicSignService(
    body: any | undefined
  ): Promise<any> {
    let url_ =
      this.baseUrl +
      `/api/services/app/AgreementTemplate/AsyncElectronicSignService`;
    url_ = url_.replace(/[?&]$/, "");

    const content_ = body;

    let options_ = <AxiosRequestConfig>{
      data: content_,
      method: "POST",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }
}
