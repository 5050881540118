<template>
  <a-spin :spinning="spinning">
    <section>
      <div class="modal-header">
        <div class="modal-title">
          <a-icon type="sync" />
          <span style="margin-left: 10px">[非自然人]同步项目协议</span>
        </div>
      </div>
      <ele-table
        :columns="columns"
        :table-data="tableData"
        :total-items="totalItems"
        :current-page="pageNumber"
        :actions-type="actionsType"
        :is-full="false"
        @emitRefreshData="getData"
        @emitSelectChange="updateSelectChange"
        @emitOnPageChange="updatePageChange"
        @emitShowSizeChange="updateShowSizeChange"
      >
      </ele-table>

      <div class="modal-footer">
        <a-button @click="close()" type="button" style="margin-right: 8px">
          <a-icon type="close-circle" />
          {{ l("关闭") }}
        </a-button>
        <a-popconfirm
          title="确定要批量更新到最新版本吗?"
          ok-text="确定"
          cancel-text="取消"
          :disabled="selectedRowKeys.length === 0"
          @confirm="batchSyncTemplete"
        >
          <a-button
            :loading="loading"
            :type="'primary'"
            :disabled="selectedRowKeys.length === 0"
          >
            <a-icon type="sync" />
            {{ l("批量更新") }}
          </a-button>
        </a-popconfirm>
      </div>
    </section>
  </a-spin>
</template>

<script>
import { AppComponentBase, ModalComponentBase } from "@/shared/component-base";
import { CommonServiceProxy } from "@/shared/common-service";
import { AppConsts } from "@/abpPro/AppConsts";
import { environment } from "@/environments/environment";
import EleTable from "@/components/ele-table";
import { ModalHelper } from "@/shared/helpers";
import moment from "moment";

export default {
  name: "templete-list",
  mixins: [AppComponentBase, ModalComponentBase],
  components: { EleTable },
  data() {
    return {
      spinning: false,

      commonService: null,
      projectService: null,

      tableData: [],
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      actionsType: {},
      formItemLayout: {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 16,
        },
      },
      // 搜索
      filterText: "",
      // 选择多少项
      selectedRowKeys: [],
      request: { sorting: "", maxResultCount: 50, skipCount: 0 }, //sorting {排序项 ASC/DESC}
      // 用户表格
      columns: [
        {
          title: this.l("项目名称"),
          dataIndex: "projectName",
          // sorter: true,
          align: "center",
          scopedSlots: { customRender: "projectName" },
        },
        {
          title: this.l("项目代码"),
          dataIndex: "projectCode",
          // sorter: true,
          align: "center",
          scopedSlots: { customRender: "projectCode" },
        },
        {
          title: this.l("签署方式"),
          dataIndex: "isOrderSignStr",
          sorter: false,
          width: 80,
          align: "center",
          scopedSlots: { customRender: "isOrderSignStr" },
        },
        {
          title: this.l("是否作废"),
          dataIndex: "isInvalidStr",
          width: 80,
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "isInvalidStr" },
        },
        {
          title: this.l("当前版本"),
          dataIndex: "agreementTemplateVersion",
          sorter: false,
          width: 75,
          align: "center",
          scopedSlots: { customRender: "agreementTemplateVersion" },
        },
        {
          title: this.l("最新版本"),
          dataIndex: "agreementTemplateLatestVersion",
          sorter: false,
          width: 75,
          align: "center",
          scopedSlots: { customRender: "agreementTemplateLatestVersion" },
        },
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          fixed: "right",
          // sorter: true,
          align: "center",
          scopedSlots: { customRender: "actions" },
        },
      ],
    };
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.fullData(); // 模态框必须,填充数据到data字段

    this.uploadUrl = AppConsts.uploadBaseUrl + "/API/Upload";
    this.subdirName = environment.production ? "abpProd" : "abpDev";
  },
  mounted() {
    if (this.params) {
      this.$nextTick(() => {});
    }
    this.initActionsType();
    this.getData();
  },
  methods: {
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      console.log({ page, pageSize });
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { size } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
    async getData() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/UnnaturalPersonProjectAgreement/GetPagedByAgreementTemplateId",
          params: {
            agreementTemplateId: this.agreementTemplateId,
            filterText: this.filterText,
            sorting: this.request.sorting,
            maxResultCount: this.request.maxResultCount,
            skipCount: this.request.skipCount,
          },
        });
        console.log(res);
        this.tableData = res.items.map((item) => {
          return {
            ...item,
            isInvalidStr: item.isInvalid ? "是" : "否",
            invalidedTime: item.invalidedTime
              ? moment(item.invalidedTime).format("YYYY-MM-DD HH:mm:ss")
              : "-",
            invalidReason: item.invalidReason ? item.invalidReason : "-",
            isDefaultStr: item.isDefault ? "是" : "否",
            isOrderSignStr: item.isOrderSign ? "指定签署" : "默认签署",
          };
        });
        this.totalItems = res.totalCount;
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    previewTemplete(item) {
      window.open(`${AppConsts.uploadBaseUrl}${item.pdfTemplateUrl}`);
    },
    batchSyncTemplete() {
      //   console.log(this.selectedRowKeys);
      //   console.log(this.agreementTemplateId);
      this.loading = true;
      const closeLoading = this.$message.loading("正在批量更新中...", 0);
      this.commonService
        .post({
          url: "/api/services/app/UnnaturalPersonProjectAgreement/BatchSyncToAppointVersionAgreementTemplate",
          params: {
            projectAgreementIds: this.selectedRowKeys,
            agreementTemplateHistoryId: null,
          },
        })
        .then((_) => {
          this.getData();
          closeLoading();
          this.loading = false;
          this.$message.success("更新成功", 1.5);
          this.success(true);
        })
        .catch((_) => {
          closeLoading();
          this.loading = false;
        });
    },
    syncTemplete(item) {
      const that = this;
      const latestPdfTemplateUrl = `${AppConsts.uploadBaseUrl}${item.latestPdfTemplateUrl}`;
      this.$confirm({
        title: "确定要将协议模板更新到最新吗？",
        content: (h) => (
          <div>
            <div style="color:red;">
              确定更新后项目协议将使用最新的模板进行签署。
            </div>
            <a href={latestPdfTemplateUrl} target="_blank">
              点击预览模板
            </a>
          </div>
        ),
        okText: "确定",
        cancelText: "取消",
        onOk() {
          const closeLoading = that.$message.loading("正在更新中...", 0);
          that.commonService
            .get({
              url: "/api/services/app/UnnaturalPersonProjectAgreement/SyncToAppointVersionAgreementTemplate",
              params: {
                id: item.id,
              },
            })
            .then((_) => {
              that.getData();
              closeLoading();
              that.$message.success("更新成功", 1.5);
            })
            .catch((_) => {
              closeLoading();
            });
        },
      });
    },
    initActionsType() {
      let _this = this;
      let obj = {
        type: "templete-list",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: true,
              name: this.l("预览模板"),
              icon: "rocket",
              fn: (data) => {
                _this.previewTemplete(data);
              },
            },
            {
              granted: true,
              name: this.l("更新模板"),
              icon: "sync",
              fn: (data) => {
                _this.syncTemplete(data);
              },
            },
          ],
        },
      };
      this.actionsType = obj;
    },
  },
};
</script>

<style scoped lang="less">
@import "../../../../styles/common.less";

.modal-header {
  .anticon-share-alt {
    margin-right: 10px;
  }
}
.form-wrapper {
  .ant-form-item {
    margin-bottom: 14px;
  }
}
</style>
