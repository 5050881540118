<template>
  <div class="agreement-category">
    <a-spin :spinning="spinning">
      <!-- <react-table
        ref="listTable"
        :table-data="tableData"
        :total-items="totalItems"
        :columns="columns"
        :showTotal="showTotalFun"
        :actions-type="actionsType"
        :table-key="tableKey"
        :hide-row-selection="true"
        :is-full="true"
        :currentPage="pageNumber"
        @emitRefreshData="getData"
        @emitSortData="updateSortData"
        @emitSelectChange="updateSelectChange"
        @emitOnPageChange="updatePageChange"
        @emitShowSizeChange="updateShowSizeChange"
      > -->
      <ele-table
        :columns="columns"
        :table-data="tableData"
        :total-items="totalItems"
        :is-full="true"
        :actionsType="actionsType"
        :current-page="pageNumber"
        :hide-row-selection="true"
        @emitRefreshData="getData"
        @emitSelectChange="updateSelectChange"
        @emitOnPageChange="updatePageChange"
        @emitShowSizeChange="updateShowSizeChange"
      >
        <a-row type="flex" align="middle" justify="space-between">
          <!-- 搜索 -->
          <a-col :span="6">
            <a-input-search
              v-if="isGranted('Pages.AgreementTemplate.Query')"
              name="filterText"
              :placeholder="l('SearchWithThreeDot')"
              @search="searchData"
              enterButton
              v-model.trim="filterText"
              v-decorator="['filterText']"
            />
          </a-col>
          <a-col :span="6" style="text-align: right">
            <a-button
              :type="'primary'"
              @click="goTemplete('create')"
              v-if="isGranted('Pages.AgreementTemplate.Create')"
            >
              <span>添加协议模版</span>
            </a-button>
          </a-col>
        </a-row>
      </ele-table>
    </a-spin>
  </div>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { AgreementTemplateServiceProxy } from "./services/agreement-templete-proxies";
import { CommonServiceProxy } from "@/shared/common-service";
// import moment from "moment";
import EleTable from "@/components/ele-table";
import { AppConsts } from "@/abpPro/AppConsts";
import SignMethod from "./sign-method/sign-method";
import SyncToProject from "./sync-to-project";
import UnnaturalSyncToProject from "./sync-to-project-u";

export default {
  mixins: [AppComponentBase],
  name: "agreement-category",
  components: {
    EleTable,
  },
  data() {
    return {
      agreementCategoryService: null,
      tableKey: "ContentOfAgreementTemplatePagedDto",
      tableName: "",
      name: "",
      // 表格
      spinning: false,
      // 搜索
      filterText: "",
      // 选择多少项
      selectedRowKeys: [],
      tableData: [],
      // 表格
      columns: [
        {
          title: this.l("协议名称"),
          dataIndex: "name",
          sorter: false,
          align: "center",
          ellipsis: true,
          width: 300,
          scopedSlots: { customRender: "name" },
        },
        {
          title: this.l("签署类型"),
          dataIndex: "signType",
          sorter: true,
          align: "center",
          // width: "150px",
          scopedSlots: { customRender: "signType" },
        },
        {
          title: this.l("所属分类"),
          dataIndex: "agreementCategoryName",
          sorter: true,
          align: "center",
          // width: "150px",
          scopedSlots: { customRender: "agreementCategoryName" },
        },
        {
          title: this.l("状态"),
          dataIndex: "status",
          sorter: true,
          align: "center",
          // width: 120,
          type: "tag",
          scopedSlots: { customRender: "status" },
          customRender: (text) => {
            // return text;
            if (text == "启用") {
              return <a-tag color="#87d068">启用</a-tag>;
              // return <a-switch default-checked />;
            }
            return <a-tag color="#f50">禁用</a-tag>;
          },
        },
        {
          title: this.l("是否同步"),
          dataIndex: "isAsync",
          sorter: true,
          align: "center",
          // width: 120,
          type: "tag",
          scopedSlots: { customRender: "isAsync" },
          customRender: (text) => {
            // return text;
            if (text == "是") {
              return <a-tag color="#87d068">{{ text }}</a-tag>;
              // return <a-switch default-checked />;
            }
            return <a-tag color="#f50">{{ text }}</a-tag>;
          },
        },
        {
          title: this.l("版本"),
          dataIndex: "version",
          sorter: false,
          align: "center",
          // width: 80,
          scopedSlots: { customRender: "version" },
        },
        // {
        //   title: this.l("排序"),
        //   dataIndex: "sort",
        //   sorter: false,
        //   align: "center",
        //   width: "150",
        //   scopedSlots: { customRender: "sort" },
        // },
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          fixed: "right",
          sorter: false,
          align: "center",
          width: 130,
          scopedSlots: { customRender: "actions" },
        },
      ],
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: { sorting: "", maxResultCount: 50, skipCount: 0 }, //sorting {排序项 ASC/DESC}
      // list-table btns显示参数
      actionsType: {},
    };
  },
  created() {
    this.agreementCategoryService = new AgreementTemplateServiceProxy(
      this.$apiUrl,
      this.$api
    );
    this.getData();
    this.initActionsType();
  },
  methods: {
    // 获取数据
    async getData() {
      this.spinning = true;
      try {
        let res = await this.agreementCategoryService.agreementTemplateGetPaged(
          this.name,
          this.filterText,
          this.request.sorting,
          this.request.maxResultCount,
          this.request.skipCount
        );
        this.spinning = false;
        this.tableData = res.items.map((val) => {
          return {
            ...val,
            status: val.status === "Enabled" ? "启用" : "禁用",
            isAsync: val.isAsync ? "是" : "否",
            statusColor: val.status === "Enabled" ? "#87d068" : "#f50",
            isAsyncColor: val.isAsync ? "#87d068" : "#f50",
          };
        });
        this.totalItems = res.totalCount;
        this.totalPages = Math.ceil(
          res.totalCount / this.request.maxResultCount
        );
        this.pagerange = [
          (this.pageNumber - 1) * this.request.maxResultCount + 1,
          this.pageNumber * this.request.maxResultCount,
        ];
      } catch (error) {
        this.spinning = false;
      }
    },
    // 初始化需要显示的func btn
    initActionsType() {
      let _this = this;
      let obj = {
        type: "AgreementTemplete",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: this.isGranted("Pages.AgreementTemplate.PrewPdf"),
              name: this.l("预览模版"),
              icon: "edit",
              fn: (data) => {
                _this.previewItem(data, "preview");
              },
            },
            {
              granted: this.isGranted("Pages.AgreementTemplate.Edit"),
              name: this.l("修改"),
              icon: "profile",
              fn: (data) => {
                if (data.canEdit) {
                  _this.goTemplete(data.id);
                } else {
                  _this.$message.info("协议模板与项目关联后，不允许再编辑");
                }
              },
            },
            {
              granted: this.isGranted("Pages.AgreementTemplate.Sync"),
              name: this.l("同步协议模板"),
              icon: "sync",
              fn: (data) => {
                _this._agreementTemplateAsyncElectronicSignService(data.id);
              },
            },
            {
              granted: this.isGranted("Pages.AgreementTemplate.SignWay"),
              name: this.l("签署方式管理"),
              icon: "hdd",
              fn: (data) => {
                _this.createOrEditSignMethod(data);
              },
            },
            {
              granted: this.isGranted(
                "Pages.AgreementTemplate.BatchSyncProjectAgreement"
              ),
              name: this.l("同步到项目"),
              icon: "sync",
              fn: (data) => {
                _this.syncToProject(data);
              },
            },
            {
              granted: this.isGranted(
                "Pages.AgreementTemplate.UnnaturalBatchSyncProjectAgreement"
              ),
              name: this.l("[非]同步到项目"),
              icon: "sync",
              fn: (data) => {
                _this.unnaturalSyncToProject(data);
              },
            },
          ],
          delete: {
            granted: this.isGranted("Pages.AgreementTemplate.Delete"),
            name: this.l("Delete"),
            fn: (data) => {
              _this.deleteItem(data.id);
            },
          },
        },
      };
      this.actionsType = obj;
    },
    previewItem(item) {
      const { pdfTemplateUrl } = item;
      window.open(`${AppConsts.uploadBaseUrl}${pdfTemplateUrl}`);
    },
    async _agreementTemplateAsyncElectronicSignService(id) {
      // let { id } = newV.item;
      this.spinning = true;
      try {
        const res =
          await this.agreementCategoryService.agreementTemplateAsyncElectronicSignService(
            { id: id }
          );
        this.getData();
        this.$notification["success"]({
          message: this.l("同步成功"),
        });
      } catch (error) {
        this.spinning = false;
      }
    },
    async deleteItem(id) {
      // let { id } = newV.item;
      this.spinning = true;
      try {
        let res = await this.agreementCategoryService.agreementTemplateDelete(
          id
        );
        this.spinning = false;
        this.getData();
        this.$notification["success"]({
          message: this.l("SuccessfullyDeleted"),
        });
      } catch (error) {
        this.spinning = false;
      }
    },
    unnaturalSyncToProject(item) {
      ModalHelper.create(
        UnnaturalSyncToProject,
        {
          agreementTemplateId: item.id,
        },
        {
          width: "930px",
        }
      ).subscribe((res) => {
        if (res) {
          this.getData();
        }
      });
    },
    syncToProject(item) {
      ModalHelper.create(
        SyncToProject,
        {
          agreementTemplateId: item.id,
        },
        {
          width: "930px",
        }
      ).subscribe((res) => {
        if (res) {
          this.getData();
        }
      });
    },
    createOrEditSignMethod(item) {
      ModalHelper.create(SignMethod, {
        params: {
          agreementTemplateId: item.id,
          canEdit: item.canEdit,
          agreementType: item.agreementType,
        },
      }).subscribe((res) => {
        // this.clearFilterAndRefresh();
        this.getData();
      });
    },
    searchData() {
      this.pageNumber = 1;
      this.request.skipCount = 0;
      this.getData();
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.request = {
        sorting: "",
        maxResultCount: this.request.maxResultCount,
        skipCount: 0,
      };
      this.name = "";
      this.filterText = "";
      this.pageNumber = 1;
      this.getData();
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // 分页事件
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      console.log({ page, pageSize });
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
    // 去模版
    goTemplete(id) {
      this.$router.push({
        path: `/app/fs/agreementTemplete/${id}`,
      });
    },
  },
};
</script>

<style scoped lang="less">
.agreement-category {
  min-height: 600px;
}
</style>
