import 'rxjs/add/operator/finally';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { CommonProcess } from "@/shared/common-process";

const cp = new CommonProcess()

export class AgreementSignMethodServiceProxy {
  private instance: AxiosInstance;
  private baseUrl: string;
  protected jsonParseReviver:
    | ((key: string, value: any) => any)
    | undefined = undefined;

  constructor(baseUrl?: string, instance?: AxiosInstance) {
    this.instance = instance ? instance : axios.create();
    this.baseUrl = baseUrl ? baseUrl : "";
  }

  /**
   * 签署方式列表
   */
  agreementSigningMethodGetList(id: string): Promise<any> {
    let url_ = this.baseUrl + `/api/services/app/AgreementSigningMethod/GetList?`;
    if (id === null) {
      throw new Error("The parameter 'agreementTemplateId' cannot be null.");
    } else if (id !== undefined) {
      url_ += "agreementTemplateId=" + encodeURIComponent("" + id) + "&";
    }
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        "Accept": "text/plain"
      }
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  /**
   * 获取单条
   * @param id 
   */
  agreementSigningMethodGetEditById(id: string): Promise<any> {
    let url_ = this.baseUrl + `/api/services/app/AgreementSigningMethod/GetEditById?`;
    if (id === null) {
      throw new Error("The parameter 'id' cannot be null.");
    } else if (id !== undefined) {
      url_ += "id=" + encodeURIComponent("" + id) + "&";
    }
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        "Accept": "text/plain"
      }
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  /**
   * 
   * @param body 保存签署方式
   */
  agreementSigningMethodSave(body: any | undefined): Promise<any> {
    let url_ = this.baseUrl + `/api/services/app/AgreementSigningMethod/Save`;
    url_ = url_.replace(/[?&]$/, "");

    const content_ = body;

    let options_ = <AxiosRequestConfig>{
      data: content_,
      method: "POST",
      url: url_,
      headers: {
        "Accept": "text/plain"
      }
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }
  
  /** 
   *  签署方式---删除
   * @param id 
   */
  agreementSigningMethodDelete(id: number | undefined): Promise<void> {
    let url_ = this.baseUrl + "/api/services/app/AgreementSigningMethod/Delete?";
    if (id === null) {
      throw new Error("The parameter 'id' cannot be null.");
    } else if (id !== undefined) {
      url_ += "id=" + encodeURIComponent("" + id) + "&";
    }
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "DELETE",
      url: url_,
      headers: {},
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }
  

}
