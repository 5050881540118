var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('section',[_c('div',{staticClass:"modal-header"},[_c('div',{staticClass:"modal-title"},[_c('a-icon',{attrs:{"type":"unordered-list"}}),_vm._v("签署设置")],1)]),_c('ele-table',{attrs:{"columns":_vm.columns,"table-data":_vm.tableData,"current-page":_vm.pageNumber,"actions-type":_vm.actionsType,"is-full":false,"hide-row-selection":true,"hide-pagination":true}}),_c('div',{staticClass:"modal-footer"},[_c('a-button',{attrs:{"disabled":_vm.saving,"type":"button"},on:{"click":function($event){return _vm.close()}}},[_c('a-icon',{attrs:{"type":"close-circle"}}),_vm._v(" "+_vm._s(_vm.l("关闭"))+" ")],1),(_vm.params && _vm.params.canEdit)?_c('a-button',{attrs:{"loading":_vm.saving,"type":'primary'},on:{"click":function($event){return _vm.createSignType(null)}}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(" "+_vm._s(_vm.l("添加"))+" ")],1):_vm._e()],1)],1),_c('a-modal',{attrs:{"centered":"","title":_vm.inputModalParams.title,"visible":_vm.inputModalParams.visible,"confirm-loading":_vm.inputModalParams.confirmLoading,"cancelText":_vm.inputModalParams.cancelText,"okText":_vm.inputModalParams.okText,"maskClosable":_vm.inputModalParams.maskClosable,"destroyOnClose":_vm.inputModalParams.destroyOnClose,"width":_vm.inputModalParams.width},on:{"ok":_vm.inputModalParams.confirm,"cancel":_vm.inputModalParams.cancel}},[_c('div',[_c('a-form',_vm._b({attrs:{"form":_vm.form}},'a-form',_vm.formItemLayout,false),[_c('a-form-item',{attrs:{"label":"签署人类型","has-feedback":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
             'signerType',
             { rules: [{ required: true, message: '请选择类型' }] } ]),expression:"[\n             'signerType',\n             { rules: [{ required: true, message: '请选择类型' }] },\n           ]"}],attrs:{"placeholder":"请选择"},on:{"change":_vm.signTypeChange}},_vm._l((_vm.inputModalParams.signerTypeList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"签署方式","required":""}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['signMethod']),expression:"['signMethod']"}],attrs:{"button-style":"solid"}},[_c('a-radio-button',{attrs:{"value":"0"}},[_vm._v(" 手动签署 ")]),_c('a-radio-button',{attrs:{"value":"1","disabled":_vm.inputModalParams.switchDisabled}},[_vm._v(" 自动签署 ")]),_c('a-radio-button',{attrs:{"value":"2","disabled":_vm.inputModalParams.switchDisabled}},[_vm._v(" 手动触发自动签署")])],1)],1),_c('a-form-item',{attrs:{"label":"协议内容通用字段"}},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
             'generalFieldsOfAgreementContentArr',
             {
               initialValue:
                 _vm.inputModalParams.generalFieldsOfAgreementContentInitVal,
               rules: [{ type: 'array', required: true, message: '请选择' }],
             } ]),expression:"[\n             'generalFieldsOfAgreementContentArr',\n             {\n               initialValue:\n                 inputModalParams.generalFieldsOfAgreementContentInitVal,\n               rules: [{ type: 'array', required: true, message: '请选择' }],\n             },\n           ]"}],attrs:{"placeholder":"请选择","options":_vm.inputModalParams.generalFieldsOfAgreementContentList}})],1),_c('a-form-item',{attrs:{"label":"关键字签章策略","has-feedback":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
             'keywordSignStrategy',
             {
               rules: [{ required: true, message: '请选择关键字签章策略' }],
             } ]),expression:"[\n             'keywordSignStrategy',\n             {\n               rules: [{ required: true, message: '请选择关键字签章策略' }],\n             },\n           ]"}],attrs:{"placeholder":"请选择"}},_vm._l((_vm.inputModalParams.KeywordSignStrategyList),function(it,i){return _c('a-select-option',{key:i,attrs:{"value":it.value}},[_vm._v(" "+_vm._s(it.label)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"排序"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
             'sort',
             {
               rules: [
                 {
                   required: true,
                   message: '请输入',
                 } ],
               initialValue: 0,
             } ]),expression:"[\n             'sort',\n             {\n               rules: [\n                 {\n                   required: true,\n                   message: '请输入',\n                 },\n               ],\n               initialValue: 0,\n             },\n           ]"}]})],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }