<template>
  <a-spin :spinning="spinning">
    <section>
      <div class="modal-header">
        <div class="modal-title"><a-icon type="unordered-list" />签署设置</div>
      </div>

      <!-- <react-table
        ref="listTable"
        :table-data="tableData"
        :total-items="totalItems"
        :columns="columns"
        :showTotal="showTotalFun"
        :actions-type="actionsType"
        :hide-row-selection="true"
        :hide-pagination="true"
        :current-page="pageNumber"
        :scroll="{x: 0}"
      > -->
      <ele-table :columns="columns" :table-data="tableData" :current-page="pageNumber" :actions-type="actionsType"
        :is-full="false" :hide-row-selection="true" :hide-pagination="true">
      </ele-table>

      <div class="modal-footer">
        <a-button :disabled="saving" @click="close()" type="button">
          <a-icon type="close-circle" />
          {{ l("关闭") }}
        </a-button>
        <a-button v-if="params && params.canEdit" :loading="saving" :type="'primary'" @click="createSignType(null)">
          <a-icon type="plus" />
          {{ l("添加") }}
        </a-button>
      </div>
    </section>

    <a-modal centered :title="inputModalParams.title" :visible="inputModalParams.visible"
      :confirm-loading="inputModalParams.confirmLoading" :cancelText="inputModalParams.cancelText"
      :okText="inputModalParams.okText" :maskClosable="inputModalParams.maskClosable"
      :destroyOnClose="inputModalParams.destroyOnClose" :width="inputModalParams.width" @ok="inputModalParams.confirm"
      @cancel="inputModalParams.cancel">
      <div>
        <a-form :form="form" v-bind="formItemLayout">
          <a-form-item label="签署人类型" has-feedback>
            <a-select @change="signTypeChange" v-decorator="[
              'signerType',
              { rules: [{ required: true, message: '请选择类型' }] },
            ]" placeholder="请选择">
              <a-select-option v-for="(item, index) in inputModalParams.signerTypeList" :key="index" :value="item.value">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <!-- 个人 默认false -->
          <a-form-item label="签署方式" required>
            <!-- <a-switch v-model="form.isAutoSign" />
       <a-switch
              :disabled="inputModalParams.switchDisabled"
              v-decorator="[
                'isAutoSign',
                { valuePropName: 'checked', initialValue: false },
              ]"
            />
 -->
            <a-radio-group v-decorator="['signMethod']" button-style="solid">
              <a-radio-button value="0"> 手动签署 </a-radio-button>
              <a-radio-button value="1" :disabled="inputModalParams.switchDisabled">
                自动签署
              </a-radio-button>
              <a-radio-button value="2" :disabled="inputModalParams.switchDisabled">
                手动触发自动签署</a-radio-button>
            </a-radio-group>
          </a-form-item>

          <a-form-item label="协议内容通用字段">
            <a-cascader v-decorator="[
              'generalFieldsOfAgreementContentArr',
              {
                initialValue:
                  inputModalParams.generalFieldsOfAgreementContentInitVal,
                rules: [{ type: 'array', required: true, message: '请选择' }],
              },
            ]" placeholder="请选择" :options="inputModalParams.generalFieldsOfAgreementContentList" />
          </a-form-item>

          <!-- // @change="keywordSignStrategyChange" -->
          <a-form-item label="关键字签章策略" has-feedback>
            <a-select v-decorator="[
              'keywordSignStrategy',
              {
                rules: [{ required: true, message: '请选择关键字签章策略' }],
              },
            ]" placeholder="请选择">
              <a-select-option :value="it.value" v-for="(it, i) in inputModalParams.KeywordSignStrategyList" :key="i">
                {{ it.label }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="排序">
            <a-input-number v-decorator="[
              'sort',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入',
                  },
                ],
                initialValue: 0,
              },
            ]" />
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalComponentBase } from "@/shared/component-base";
import { AgreementSignMethodServiceProxy } from "../services/agreement-signmethod-proxies";
import { AppConsts } from "@/abpPro/AppConsts";
import { environment } from "@/environments/environment";
import { TableNoPage } from "@/components";
import EleTable from "@/components/ele-table";

import { AgreementTemplateServiceProxy } from "../services/agreement-templete-proxies";
import { GeneralFieldsServiceProxy } from "../../general-fields/services/general-fields-proxies";

export default {
  name: "add-extend",
  mixins: [AppComponentBase, ModalComponentBase],
  components: { EleTable },
  data () {
    return {
      spinning: false,
      // 表单
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),
      agreementSignMethodService: null,
      isDisabled: false,

      tableKey: "ContentOfAgreementTemplatePagedDto",
      generalFieldsService: null,
      tableData: [],
      totalItems: null,
      actionsType: {},
      pageNumber: 1,
      formItemLayout: {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 16,
        },
      },
      inputModalParams: {
        destroyOnClose: true,
        maskClosable: false,
        confirmLoading: false,
        visible: false,
        width: 620,
        title: "",
        cancelText: "取消",
        okText: "确认",
        form: {
          // isAutoSign: false,
          // signerType: '',
          // generalFieldsOfAgreementContentName: '',
        },
        id: null,
        signerTypeList: [], // { name: "客户", value: "Customer" }
        KeywordSignStrategyList: [
          { label: "所有关键字签章（默认）", value: 0 },
          { label: "第一个关键字签章", value: 1 },
          { label: "最后一个关键字签章", value: 2 },
        ],
        generalFieldsOfAgreementContentList: [],
        generalFieldsOfAgreementContentInitVal: [],
        switchDisabled: false,
        cancelText: "取消",
        okText: "确认",
        confirm: () => { },
        cancel: () => {
          this.inputModalParams.visible = false;
        },
      },
      // 用户表格
      columns: [
        {
          title: this.l("签署类型"),
          dataIndex: "signTypeName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "signTypeName" },
        },
        {
          title: this.l("签署方式"),
          dataIndex: "signMethodDesc",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "signMethodDesc" },
        },
        {
          title: this.l("协议内容通用字段"),
          dataIndex: "generalFieldsOfAgreementContentName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "generalFieldsOfAgreementContentName" },
        },
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          fixed: "right",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "actions" },
        },
      ],
    };
  },
  created () {
    this.generalFieldsService = new GeneralFieldsServiceProxy(
      this.$apiUrl,
      this.$api
    );

    this.fullData(); // 模态框必须,填充数据到data字段
    this.agreementSignMethodService = new AgreementSignMethodServiceProxy(
      this.$apiUrl,
      this.$api
    );
    this.uploadUrl = AppConsts.uploadBaseUrl + "/API/Upload";
    this.subdirName = environment.production ? "abpProd" : "abpDev";
  },
  mounted () {
    if (this.params) {
      this.$nextTick(() => { });
    }

    this._agreementSigningMethodGetList();
    this._generalFieldsOfAgreementContentGetList();
    this.initActionsType();
  },
  methods: {
    getSignMethodDesc (signMethod) {
      if (signMethod == 0) {
        return "手动签署";
      }
      if (signMethod == 1) {
        return "自动签署";
      }
      if (signMethod == 2) {
        return "手动触发自动签署";
      }
    },
    // get table list
    async _agreementSigningMethodGetList () {
      this.spinning = true;
      try {
        let res =
          await this.agreementSignMethodService.agreementSigningMethodGetList(
            this.params.agreementTemplateId
          );
        // console.log(res);
        this.tableData = res.map((item) => {
          return {
            ...item,
            signTypeName:
              item.signerType === "Organization"
                ? "组织架构"
                : item.signerType === "Customer"
                  ? "客户"
                  : item.signerType === "Personal"
                    ? "个人"
                    : "个人代理人",
            signMethodDesc: this.getSignMethodDesc(item.signMethod),
            isAutoSignDesc: item.isAutoSign ? "是" : "否",
          };
        });
        this.totalItems = res.length;
        this.initSignerTypeList(this.tableData);
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    // 只有客户可以添加多条
    initSignerTypeList (arr) {
      this.inputModalParams.signerTypeList = [];
      if (this.params.agreementType !== "UnnaturalPerson") {
        this.inputModalParams.signerTypeList = [
          { name: "客户", value: "Customer" },
        ];
      }
      let isHaveOrganization = false;
      let isHavePersonal = false;
      arr.map((item) => {
        if (item.signerType === "Organization") {
          isHaveOrganization = true;
        }
        // if (item.signerType === "Personal") {
        //   isHavePersonal = true;
        // }
      });
      if (!isHaveOrganization) {
        this.inputModalParams.signerTypeList.push({
          name: "组织架构",
          value: "Organization",
        });
      }
      if (!isHavePersonal) {
        this.inputModalParams.signerTypeList.push({
          name: "个人",
          value: "Personal",
        });
      }
      // 目前只支持非自然人多人签署
      if (this.params.agreementType === "UnnaturalPerson") {
        this.inputModalParams.signerTypeList.push({
          name: "个人代理人",
          value: "AgentPersonal",
        });
      }
      // console.log(this.inputModalParams.signerTypeList);
    },
    signTypeChange (value) {
      if (value === "Personal" || value === "AgentPersonal") {
        this.form.setFieldsValue({
          isAutoSign: false,
          signMethod: "0",
        });
        this.inputModalParams.switchDisabled = true;
      } else {
        this.inputModalParams.switchDisabled = false;
      }
    },
    // 修改
    editItem (id) {
      if (!this.params.canEdit) {
        this.$message.info("协议模板在项目应用后，不可编辑签署方式");
        return;
      }
      // const { id } = newV.item;
      this._agreementSigningMethodGetEditById(id);
    },
    // get item
    async _agreementSigningMethodGetEditById (id) {
      this.spinning = true;
      try {
        let res =
          await this.agreementSignMethodService.agreementSigningMethodGetEditById(
            id
          );
        this.spinning = false;
        // console.log(res)
        this.createSignType(res);
      } catch (error) {
        this.spinning = false;
      }
    },
    // save
    async _agreementSigningMethodSave (opts) {
      // console.log(opts)
      this.inputModalParams.confirmLoading = true;
      try {
        await this.agreementSignMethodService.agreementSigningMethodSave(opts);
        this.inputModalParams.confirmLoading = false;
        this.inputModalParams.visible = false;
        this.$notification["success"]({
          message: this.l("SavedSuccessfully"),
        });
        this._agreementSigningMethodGetList();
      } catch (error) {
        this.inputModalParams.confirmLoading = false;
      }
    },
    // show modal
    createSignType (item) {
      let _this = this;
      if (item) {
        this.inputModalParams.title = "修改签署方式";
      } else {
        this.inputModalParams.title = "新增签署方式";
      }
      this.inputModalParams.okText = "确认";
      this.inputModalParams.confirm = function () {
        _this.form.validateFields((err, values) => {
          if (!err) {
            // console.log("Received values of form: ", values);
            let opts = {
              agreementTemplateId: _this.params.agreementTemplateId,
              generalFieldsOfAgreementContentId:
                values.generalFieldsOfAgreementContentArr[1],
              signMethod: parseInt(values.signMethod),
              isAutoSign: parseInt(values.signMethod) == 0 ? false : true,
              signerType: values.signerType,
              sort: Number(values.sort),
              keywordSignStrategy: parseInt(values.keywordSignStrategy),
            };
            if (
              values.signerType !== "Organization" &&
              values.signerType !== "Customer" &&
              values.signerType !== "Personal" &&
              values.signerType !== "AgentPersonal"
            ) {
              opts.signerType = _this.getSignerType(values.signerType);
            }
            if (item) {
              opts.id = item.id;
            }
            // console.log(opts);
            _this._agreementSigningMethodSave(opts);
          }
        });
      };
      if (item) {
        let parentId;
        let parentName;
        let childName;
        this.inputModalParams.generalFieldsOfAgreementContentList.map(
          (it, index) => {
            it.children.map((t, i) => {
              if (t.value === item.generalFieldsOfAgreementContentId) {
                parentId = it.value;
                parentName = it.label;
                childName = t.label;
              }
            });
          }
        );
        this.inputModalParams.generalFieldsOfAgreementContentInitVal = [
          parentName,
          childName,
        ];
        let obj = {
          signerType:
            item.signerType === "Organization"
              ? "组织架构"
              : item.signerType === "Customer"
                ? "客户"
                : item.signerType === "Personal"
                  ? "个人"
                  : "个人代理人",
          isAutoSign: item.isAutoSign,
          generalFieldsOfAgreementContentArr: [
            parentId,
            item.generalFieldsOfAgreementContentId,
          ],
          sort: item.sort,
          keywordSignStrategy: item.keywordSignStrategy,
        };
        if (item.signMethod !== null) {
          obj["signMethod"] = item.signMethod.toString();
        }
        this.$nextTick(() => {
          this.form.setFieldsValue({ ...obj });
          if (
            item.signerType === "Personal" ||
            item.signerType === "AgentPersonal"
          ) {
            this.inputModalParams.switchDisabled = true;
          } else {
            this.inputModalParams.switchDisabled = false;
          }
        });
      }
      this.inputModalParams.visible = true;
    },
    // delete
    async deleteItem (id) {
      if (!this.params.canEdit) {
        this.$message.info("协议模板在项目应用后，不可删除签署方式");
        return;
      }
      // const { id } = newV.item;
      this.spinning = true;
      try {
        const res =
          await this.agreementSignMethodService.agreementSigningMethodDelete(
            id
          );
        this.spinning = false;
        this._agreementSigningMethodGetList();
        this.$notification["success"]({
          message: this.l("SuccessfullyDeleted"),
        });
      } catch (error) {
        this.spinning = false;
      }
    },
    // get general fields
    async _generalFieldsOfAgreementContentGetList () {
      this.spinning = true;
      try {
        let res =
          await this.generalFieldsService.generalFieldsOfAgreementContentGetListByTemplateId(
            this.params.agreementTemplateId
          );
        let data = res;
        this.inputModalParams.generalFieldsOfAgreementContentList =
          this.initCascaderData(data);
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    getSignerType (s) {
      switch (s) {
        case "组织架构":
          return "Organization";
        case "客户":
          return "Customer";
        case "个人":
          return "Personal";
        case "个人代理人":
          return "AgentPersonal";
      }
    },
    initCascaderData (arr) {
      const keyMap = {
        groupName: "label",
        groupId: "value",
        showName: "label",
        id: "value",
      };
      arr.forEach((obj) => {
        for (let key in obj) {
          if (key === "children" && Array.isArray(obj[key])) {
            this.initCascaderData(obj[key]);
          } else {
            let newKey = keyMap[key];
            if (newKey) {
              obj[newKey] = obj[key];
              delete obj[key];
            }
          }
        }
      });
      return arr;
    },
    showTotalFun () {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    // init func btn
    initActionsType () {
      let _this = this;
      let obj = {
        type: "AgreementTemplete",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: true,
              name: this.l("编辑"),
              icon: "edit",
              fn: (data) => {
                _this.editItem(data.id);
              },
            },
          ],
          delete: {
            granted: true,
            name: this.l("Delete"),
            fn: (data) => {
              _this.deleteItem(data.id);
            },
          },
        },
      };
      this.actionsType = obj;
      // console.log(this.actionsType);
    },
  },
};
</script>

<style scoped lang="less">
@import "../../../../styles/common.less";

.modal-header {
  .anticon-share-alt {
    margin-right: 10px;
  }
}

.form-wrapper {
  .ant-form-item {
    margin-bottom: 14px;
  }
}
</style>
